<template>
    <pass-card v-show="true">
        <template v-slot:title>

            <div
                @click="showProds = true"
                :class="{ 'title_button': true, 'text_gray': !showProds }"
            >
                <product-icon />
                <div class="title">Товары:</div>
            </div>
            <div
                @click="showProds = false"
                :class="{ 'title_button': true, 'text_gray': showProds }"
            >
                <brand-icon />
                <div class="title">Производители:</div>
            </div>

        </template>
        <template v-slot:body>
            <div v-if="showProds">
                <product-header :params="productsParamsTitles" />
                <div class="scroll">
                    <product-row
                        v-for="(product, index) in products"
                        :key="'product' + index"
                        :params="product"
                    />
                </div>
            </div>
            <div v-else>
                <product-header :params="brandsParamsTitles" />
                <div class="scroll" v-if="products">
                    <brand-row
                        v-for="(brand, index) in brands"
                        :key="'brand' + index"
                        :brand="brand"
                    />
                </div>
            </div>
        </template>
    </pass-card>
</template>

<script>
import BrandIcon from '@/components/UI/icons/BrandIcon';
import ProductIcon from '@/components/UI/icons/ProductIcon';
import ProductRow from '@/components/ProductRow';
import ProductHeader from '@/components/ProductHeader';
import { mapState } from 'vuex';
import BrandRow from '@/components/BrandRow';

export default {
    components: {
        BrandIcon,
        ProductIcon,
        ProductRow,
        ProductHeader,
        BrandRow,
    },
    data() {
        return {
            showProds: true,
            productsParamsTitles: {
                title: 'Наименование SKU',
                brand: 'Бренд',
                id: 'Сделка',
                deliveryType: 'Поставка',
                status: 'Cтатус',
                deliveryDate: 'Дата',
                quantity: 'Кол-во',
                sum: 'Сумма',
                currency: 'Валюта'
            },
            brandsParamsTitles: {
                title: 'Наименование',
                id: 'Сделка',
                deliveryType: 'Поставка',
                status: 'Cтатус',
                quantity: 'Кол-во',
                sum: 'Сумма',
                currency: 'Валюта'
            }
        }
    },
    computed: {
        ...mapState({
            products: state => state.pass.products,
        }),
        brands()
        {
            let brands = {}

            for (let i in this.products) {
                const product = this.products[i]
                let brend = product.brend ?? 'Бренд не указан'

                if (brend in brands) {
                    brands[brend].quantity += product.quantity
                    brands[brend].price = Math.ceil(brands[brend].price + product.price)
                } else {
                    brands[brend] = {
                        id: product.id,
                        name: brend,
                        quantity: product.quantity,
                        price: product.price,
                        currency: product.currency
                    }
                }
            }

            return Object.values(brands).sort((a, b) => a.name > b.name ? 1 : -1)
        }
    },
}
</script>

<style scoped>
.param {
    padding: 1rem;
    border-bottom: 1px solid rgba(222, 226, 230, 1);
    height: 3.2rem;
    display: flex;
    align-items: center;
}

.title_button {
    display: flex;
    justify-content: space-between;
    gap: 0 6px;
    cursor: pointer;
}

.scroll {
    overflow: auto;
    /* height: 10rem; */
    height: 94px;
}

.scrollbar {
    width: 122px !important;
}
</style>