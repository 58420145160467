<template>
    <span :class="[statusStyle, 'status_container']">{{ status }}</span>
</template>

<script>
export default {
    name: "product-status",
    props: {
        status: {
            type: String,
            require: true
        }
    },
    computed: {
        statusStyle() {
            const styleMap = {
                'В резерве': 'reserved',
                'Отгружено': 'done',
                'ВЭД': 'ved',
            }
            return styleMap[this.status]
        }
    }
}
</script>

<style scoped>
.reserved {
    background-color: rgba(235, 229, 252, 1);
    color: rgba(82, 39, 204, 1);
}

.done {
    background-color: rgba(142, 217, 183, 1);
    color: rgba(11, 103, 37, 1);
}

.ved {
    background-color: rgba(207, 244, 252, 1);
    color:rgba(5, 81, 96, 1);
}
</style>