<template>
    <pass-card>
        <template v-slot:title>
            <slot name="icon"></slot>
            <div class="title">{{ title }}:</div>
        </template>
        <template v-slot:body>
            <my-list v-if="parts">
                <buro-item
                    v-for="buro in members"
                    :key="buro.id"
                    :buro="buro"
                ></buro-item>
            </my-list>
        </template>
    </pass-card>
</template>

<script>
import BuroItem from '@/components/BuroItem';
import { mapState } from 'vuex';

export default {
    components: {
        BuroItem
    },
    props: {
        title: {
            type: String,
            required: true
        },
        parts: {
            type: Array,
        }
    },
    computed: {
        ...mapState({
            statusMap: state => state.pass.statusMap,
        }),
        members() {
            let members = [...this.parts]
            members.sort((a,b) => a.companyOrContact.title > b.companyOrContact.title ? 1 : -1)

            let main = []
            let registered = []
            let reReg = []
            let fail = []
            let transferred = []
            let refusal = []
            let hidden = []

            for (let i = 0; i < members.length; i++) {
                const item = members[i]
                const status = this.statusMap[item.dealId]
                
                switch (status) {
                    case 'mainRegistered':
                        main.push(item)
                        break
                    case 'registered':
                        registered.push(item)
                        break
                    case 'reReg':
                        reReg.push(item)
                        break
                    case 'fail':
                        fail.push(item)
                        break
                    case 'transferred':
                        transferred.push(item)
                        break
                    case 'refusal':
                        refusal.push(item)
                        break
                    case 'hidden':
                        hidden.push(item)
                        break
                }
            }
            const result = [...main, ...registered, ...reReg, ...fail, ...transferred, ...refusal, ...hidden]
            return result
        }
    }
}
</script>