<template>
  <a v-if="link"
    :href="'https://portal.opuscontract.ru' + link"
    @click.prevent="open"
  >
    <slot></slot>
  </a>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    link: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      bx24: state => state.pass.bx24,
    })
  },
  methods: {
    open() {
      this.bx24.openPath(this.link, err => console.log(err))
    }
  }
}
</script>