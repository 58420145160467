<template>
    <div class="text_gray">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'gray-text'
    }
</script>

<style>
.text_gray {
    color: #ADB5BD;
    font-weight: 500;
}
</style>