<template>
    <div class="field pt-2">
        <!-- <FieledSelect v-model="curBuild" :options="builds" @click="edit" /> -->
        <FieledSelect v-model="curObject" :options="objects" @click="edit" />
        <div v-if="editMode" class="buttons">
            <button class="btn save-btn" type="button" @click="saveValues">Сохранить</button>
            <button class="btn" type="button" @click="backValues">Сбросить</button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import FieledSelect from '@/components/restfields/FieldSelect'

export default {
    components: {
        FieledSelect
    },
    data() {
        return {
            editMode: false,
            curBuild: '',
            curObject: '',
            builds: [],
            objects: []
        }
    },
    watch: {
        curBuild() {
            if (this.editMode) {
                this.curObject = ''
            }
            this.fetchObjects()
        },
        editMode(newValue) {
            if (newValue) {
                BX24.resizeWindow(100, 120)
            } else {
                BX24.resizeWindow(100, 60)
            }
        }
    },
    mounted() {
        const _this = this

        BX24.init(() => {
            BX24.resizeWindow(100, 60)
            _this.fetchBuilds()
            _this.setCurValues()
            _this.setDealValues()
        })
    },
    methods: {
        async edit() {
            if (this.editMode === false) {
                this.editMode = true
            }
        },
        async fetchBuilds() {
            this.builds = await this.crmItemList(171)
        },
        async fetchObjects() {
            this.objects = await this.crmItemList(162, { PARENT_ID_171: this.curBuild })
        },
        async crmItemList(entityId, filter = {}) {
            const request = await new Promise((resolve, reject) => {
                BX24.callMethod('crm.item.list',
                    {
                        entityTypeId: entityId,
                        filter: filter
                    },
                    function (bxdata) {
                        resolve(bxdata)
                    })
            })

            return request.answer.result.items
        },
        async setDealValues() {
            let value = await new Promise((resolve, reject) => {
                BX24.callMethod(
                    'crm.deal.get',
                    { id: BX24.placement.info().options.ENTITY_VALUE_ID },
                    function (bxdata) {
                        resolve(bxdata)
                    }
                )
            })
            // console.log(value.data())
            // console.log(value.data().PARENT_ID_171)
            this.curBuild = value.data().PARENT_ID_171
            this.curObject = value.data().PARENT_ID_162
        },
        setCurValues() {
            const value = BX24.placement.info().options.VALUE.split('|') //достанем сохраненное значение из плейсмента, разобъем по разделителю 

            // this.curBuild = value[0]

            // var value = BX24.callMethod(
            //   'crm.deal.get', 
            //   { id: BX24.placement.info().options.ENTITY_VALUE_ID },
            //   function (result) {
            //     if (result.error())
            //       console.log(result.error())
            //     else
            //       // this.curBuild = result.data().PARENT_ID_171
            //       // this.curObject = result.data().PARENT_ID_162
            //       return result.data()
            //   }
            // )

            // console.log(value)
            this.curBuild = value.PARENT_ID_171
            this.curObject = value.PARENT_ID_162
        },
        saveValues() {
            BX24.callMethod(
                'crm.deal.update',
                {
                    id: BX24.placement.info().options.ENTITY_VALUE_ID,
                    fields: {
                        PARENT_ID_162: this.curObject
                    }
                }
            )
            this.editMode = false

            const placement = BX24.placement.info()
            let method = 'crm.deal.update'
            const value = `${this.curBuild}|${this.curObject}`

            if (placement.options.MODE !== 'view') {
                BX24.placement.call('setValue', value)
                return;
            }

            let settings = {
                id: placement.options.ENTITY_VALUE_ID,
                fields: {}
            }

            settings.fields[placement.options.FIELD_NAME] = value

            if (placement.options.ENTITY_ID !== 'CRM_DEAL') {
                method = 'crm.item.update'
                settings[entityTypeId] = placement.options.ENTITY_ID
            }

            BX24.callMethod(method, settings)
        },
        backValues() {
            this.setCurValues()
            this.editMode = false
        }
    }
}
</script>

<style scoped>
.field {
    height: 100%;
    background-color: #f9fafb;
    display: grid;
}

.buttons {
    display: flex;
    justify-content: center;
    height: 40px;
}

.save-btn {
    background-color: #bbed21;
}

.btn {
    font-family: 'Segoe UI';
    font-size: 12px;
    color: #535c69;
    font-stretch: condensed;
    text-transform: uppercase;
    font-weight: 600;
    margin-right: .25rem !important;
    margin-left: .25rem !important;
    box-shadow: none !important;

    display: inline-block;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    padding: .375rem .75rem;
    border-radius: .3rem;
}
</style>