import { createApp } from "vue"
import App from "@/App.vue"
import components from "@/components/UI"
import router from "@/router/router"
import store from "@/store"

const app = createApp(App)

const numberFormat = (() => new Intl.NumberFormat("ru-RU").format)()

app.config.globalProperties.numberFormat = (value) =>
  isFinite(value) ? numberFormat(value) : "-"

components.forEach((component) => {
  app.component(component.name, component)
})

app.use(store).use(router).mount("#app")
