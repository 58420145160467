<template>
	<router-view></router-view>
</template>

<style>
.scrollbar::-webkit-scrollbar {
	display: block;
	overflow: auto;
	width: 5px;
	height: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
	background: rgba(233, 236, 239, 1);
	border-radius: 15%;
}

.badge-title-card {
	background-color: #e9e7ee;
	color: #2067B0;
}

.success {
	background-color: #D1E7DD;
	color: #0A3622;
}

.danger {
	background-color: #F8D7DA;
	color: #58151C;
}

@media all and (min-width: 1026px) {
	body {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 0.8rem;
		background-color: #eef2f4;
	}

	a {
		text-decoration: none;
		color: #2067B0;
	}

	.b24-block1 {
		height: 11.9rem;
		/* height: 25%; */
	}

	.b24-block2 {
		height: 9.8rem;
		/* height: 25%; */
	}
}

@media all and (max-width: 1025px) {
	body {
		font-family: 'Inter';
		font-style: normal;
		font-size: 0.7rem;
		background-color: #eef2f4;
	}

	a {
		text-decoration: none;
		color: #2067B0;
	}

	.b24-block2 {
		height: 8.5rem;
	}
}

@media all and (min-width: 65px) {

	html,
	body {
		height: 100%;
	}

	#app {
		height: 100%;
	}
}
</style>