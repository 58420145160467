<template>
    <div class="list_row">
        <div class="section w-50 f-item">
            <slot name="leftSide"></slot>
        </div>
        <div 
            :title="title"
            class="section w-25"
        >
            <div><user-icon style="margin-right: 0.25rem;"></user-icon></div>
            <open-path
                :link="link"
                target="_blank"
            >{{ title }}</open-path>
        </div>
        <div class="section w-25">
            <div class="item-end">
                <slot name="rightSide"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import OpenPath from '@/components/OpenPath';

export default {
    components: {
        OpenPath,
    },
    props: {
        title: {
            type: String,
            required: true
        },
        link: {
            required: true
        }
    }
}
</script>

<style scoped>
.list_row {
    width: auto;
    min-height: 2.6rem;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(222, 226, 230, 1);
}

.list_row:last-child {
    border-bottom: 0;
}

.section {
    /* height: 22px; */
    display: flex;
    align-items: center;
    overflow: hidden; 
    width: 15rem;
}

.section > a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.item-end {
    margin-left: auto;
}
.f-item {
    padding-right: 2rem;
}
</style>
