import PassCard from '@/components/UI/PassCard'
import MyList from '@/components/UI/MyList'
import UserIcon from '@/components/UI/icons/UserIcon'
import GrayText from '@/components/UI/GrayText'
import ProductStatus from '@/components/UI/ProductStatus'

export default [
    PassCard,
    UserIcon,
    MyList,
    GrayText,
    ProductStatus
]