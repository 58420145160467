<template>
	<div class="products">
		<div class="product__param_title">{{ brand.name }}</div>
		<div class="product__param">
			<open-path :class="[classObject, statusStyle]" :link="'/crm/deal/details/' + brand.id + '/'" target="_blank">
				#{{ brand.id }}
			</open-path>
		</div>
		<div class="product__param">-</div>
		<div class="product__param">-</div>
		<div class="product__param">{{ numberFormat(brand.quantity) }}</div>
		<div class="product__param">{{ numberFormat(brand.price) }}</div>
		<div class="product__param">{{ brand.currency }}</div>
	</div>
</template>

<!-- <script>
export default {
	props: {
		brand: {
			type: Object,
			required: true
		}
	}
}
</script> -->

<script>
import { mapState } from 'vuex';
import OpenPath from '@/components/OpenPath';
export default {
    components: {
        OpenPath,
    },
    data() {
        return {
            classObject: {
                'status_container': true
            }
        }
    },
    props: {
        brand: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            statusMap: state => state.pass.statusMap
        }),
        statusStyle() {
            const titleMap = {
                mainRegistered: 'mainRegistered',
                registered: 'registered',
                reReg: 're-registration',
                fail: 'dismissed',
                transferred: 'transferred',
                refusal: 'refusal',
                hidden: 'hidden'
            }
            return titleMap[this.statusMap[this.brand.id]]
        }
    }
}
</script>

<style scoped>
.mainRegistered {
    background-color: rgba(142, 217, 183, 1);
    color: #0E4F31;
}

.registered {
    background-color: rgba(209, 231, 221, 1);
    color: rgba(17, 89, 56, 1);
}

.re-registration {
    background-color: rgba(255, 243, 205, 1);
    color:rgba(102, 77, 3, 1);
}
.dismissed {
    background-color: rgba(243, 206, 194, 1);
    color: rgba(216, 79, 60, 1);
}
.transferred {
    background-color: rgba(186, 221, 255, 1);
    color: rgba(0, 129, 255, 1);
}
.refusal {
    background-color: rgba(243, 206, 194, 1);
    color: rgba(216, 79, 60, 1);
}
</style>