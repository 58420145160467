<template>
    <div>
        <button @click="changeField('add')"> добавить тип поля "Здание + Объекты" </button>
        <button @click="changeField('delete')"> удалить </button>
        <button @click="changeField('update')"> обновить </button>
    </div>
    <!-- <div>
        <button @click="changeFieldDev('add')"> добавить (dev) тип поля "Здание + Объекты" </button>
        <button @click="changeFieldDev('delete')"> удалить (dev)</button>
        <button @click="changeFieldDev('update')"> обновить (dev)</button>
    </div> -->
</template>

<script>
export default {
    data() {
        return {
            data: ''
        }
    },
    methods: {
        changeField(method) {
            const _this = this
            BX24.init(() => {
                _this.data = BX24.callMethod(
                    'userfieldtype.' + method,
                    {
                        USER_TYPE_ID: 'building_object',
                        HANDLER: 'https://projectpass.opuscontract.ru/restfields/buildingobject',
                        TITLE: 'Здание + объект 0.1',
                        DESCRIPTION: 'рест поле для разработки',
                        OPTIONS: {
                            'height': 60,
                        },
                    }
                )
            })
            // console.log(this.data)
        },
        // changeFieldDev(method) {
        //     const _this = this
        //     BX24.init(() => {
        //         _this.data = BX24.callMethod(
        //             'userfieldtype.' + method,
        //             {
        //                 USER_TYPE_ID: 'building_object_dev',
        //                 HANDLER: 'https://opuscontractb24.dev/restfields/buildingobject',
        //                 TITLE: 'Здание + объект (dev)',
        //                 DESCRIPTION: 'рест поле для разработки',
        //                 OPTIONS: {
        //                     'height': 60,
        //                 },
        //             }
        //         )
        //     })
        //     console.log(this.data)
        // }
    }
}
</script>

<style scoped></style>