<template>
    <span :class="[classObject, statusStyle]"><slot></slot></span>
</template>

<script>
export default {
    data() {
        return {
            classObject: {
                'status_container': true
            }
        }
    },
    props: {
        type: {
            type: String,
            require: true
        }
    },
    computed: {
        statusStyle() {
            const styleMap = {
                done: 'registered',
            }
            return styleMap[this.type]
        }
    }
}
</script>

<style scoped>
.registered {
    background-color: rgba(209, 231, 221, 1);
    color: rgba(17, 89, 56, 1);
}

</style>