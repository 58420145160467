<template>
    <pass-row v-if="buro.companyOrContact.id > 0"
        :title="buro.companyOrContact.title"
        :link="'/crm/' + buro.companyOrContact.type + '/details/' + buro.companyOrContact.id + '/'"
    >
        <template v-slot:leftSide>
            <open-path v-if="buro.id > 0"
                class="role"
                :link="'/crm/type/145/details/' + buro.id + '/'"
                target="_blank"
                :title="buro.title"
            >{{ buro.title }}</open-path>
            <open-path v-else
                class="role"
                :title="buro.title"
            >{{ buro.title }}</open-path>
        </template>
        <template v-slot:rightSide>
            <deal-status
                :type="statusMap[buro.dealId]"
                :title="buro.subrole"
            />
        </template>
    </pass-row>
    <pass-row v-else
        :title="buro.companyOrContact.title"
    >
        <template v-slot:leftSide>
            <open-path v-if="buro.id > 0"
                class="role"
                :link="'/crm/type/145/details/' + buro.id + '/'"
                target="_blank"
                :title="buro.title"
            >{{ buro.title }}</open-path>
            <open-path v-else
                class="role"
                :title="buro.title"
            >{{ buro.title }}</open-path>
        </template>
        <template v-slot:rightSide>
            <deal-status
                :type="statusMap[buro.dealId]"
                :title="buro.subrole"
            />
        </template>
    </pass-row>
</template>

<script>
import PassRow from '@/components/UI/PassRow';
import DealStatus from '@/components/UI/DealStatus'
import { mapState } from 'vuex';
import OpenPath from '@/components/OpenPath';

export default {
    components: {
        DealStatus,
        PassRow,
        OpenPath,
    },
    props: {
        buro: {
            type: Object,
            // requared: true
            required: true
        }
    },
    computed: {
        ...mapState({
            statusMap: state => state.pass.statusMap
        }),
    }
}
</script>

<style scoped>
.role {
    /* height: 1rem; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}</style>
