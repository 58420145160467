<template>
    <div
        v-if="isLoading"
        class="loading"
    >
        <h4>Загрузка...</h4>
    </div>
    <div
        v-else-if="projectIsset === false && error !== ''"
        class="loading"
    >
        <h4>Ошибка: {{ error }}</h4>
    </div>
    <div
        v-else
        class="pasport"
    >
        <div class="pass-row">
            <div class="pass-column">
                <project-card></project-card>
            </div>
            <div class="pass-column">
                <building-card></building-card>
            </div>
        </div>
        <div class="pass-row">
            <div class="pass-column">
                <partic-card
                    style="min-height: 145px;"
                    :parts="members?.bureau"
                    :title="'Архитектурное бюро'"
                >
                    <template v-slot:icon><buro-icon /></template>
                </partic-card>
            </div>
            <div class="pass-column">
                <partic-card
                    style="min-height: 145px;"
                    :parts="members?.customers"
                    :title="'Подрядчики и дилеры'"
                >
                    <template v-slot:icon><contractors-icon /></template>
                </partic-card>
            </div>
        </div>
        <div class="pass-row">
            <div class="pass-column">
                <partic-card
                    style="min-height: 145px;"
                    :parts="members?.contractors"
                    :title="'Заказчики проекта'"
                >
                    <template v-slot:icon><castomers-icon /></template>
                </partic-card>
            </div>
            <div class="pass-column">
                <partic-card
                    style="min-height: 145px;"
                    :parts="members?.competitors"
                    :title="'Конкуренты'"
                >
                    <template v-slot:icon><concurent-icon /></template>
                </partic-card>
            </div>
        </div>
        <div class="pass-row">
            <div class="pass-column-max">
                <product-card
                    style="min-height: 159px;"
                ></product-card>
            </div>
        </div>
    </div>
</template>

<script>
import BuroIcon from '@/components/UI/icons/BuroIcon';
import ConcurentIcon from '@/components/UI/icons/ConcurentIcon';
import CastomersIcon from '@/components/UI/icons/CastomersIcon';
import ContractorsIcon from '@/components/UI/icons/ContractorsIcon';
import ProductCard from '@/components/ProductCard';
import ParticCard from '@/components/ParticCard';
import BuildingCard from '@/components/BuildingCard';
import ProjectCard from '@/components/ProjectCard';
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
    components: {
        BuildingCard,
        ProjectCard,
        ParticCard,
        ProductCard,
        BuroIcon,
        ConcurentIcon,
        CastomersIcon,
        ContractorsIcon
    },
    data() {
        return {
            deal: 0,
            user: 0,
            data: {}
        }
    },
    mounted() {
        this.getPlace()
    },
    computed: {
        ...mapState({
            isLoading: state => state.pass.isLoading,
            members: state => state.pass.members,
            projectIsset: state => state.pass.projectIsset,
            error: state => state.pass.error,
        }),
        userId: {
            get() {
                return this.user;
            },
            set(newValue) {
                this.user = newValue
                this.setUser(this.user)
            }
        },
        dealId: {
            get() {
                return this.deal;
            },
            set(newValue) {
                this.deal = newValue
                this.setPlacement(newValue)
                this.fetchProject().then(() => {
                    if (this.projectIsset) {
                        this.fetchBuilding()
                        this.fetchObject()
                        this.fetchMembers()
                        this.fetchProducts()
                    }
                })
            }
        }
    },
    methods: {
        ...mapMutations({
            setAuth: 'pass/setAuth',
            setPlacement: 'pass/setPlacement',
            setUser: 'pass/setUser',
            setbx24: 'pass/setbx24',
        }),
        ...mapActions({
            fetchProject: 'pass/fetchProject',
            fetchBuilding: 'pass/fetchBuilding',
            fetchObject: 'pass/fetchObject',
            fetchMembers: 'pass/fetchMembers',
            fetchProducts: 'pass/fetchProducts',
        }),
        async getPlace() {
            const _this = this

            BX24.init(() => {
                BX24.callMethod('user.current', {
                    auth: BX24.getAuth().access_token
                }, function(res) {
                    _this.userId = res.answer.result.ID;
                    _this.dealId = BX24.placement.info().options.ID
                })

                BX24.resizeWindow(window.innerWidth, 650)
            })

            this.setbx24(BX24)
        }
    }
}
</script>

<style>
* {
    font-family: 'Inter';
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
}

.pasport {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    gap: 10px;
}

.pasport .pass-row {
    display: flex;
    gap: 10px;
    width: 100%;
}

.pasport .pass-row .pass-column {
    flex: 1;
    width: 48%;
}

.pasport .pass-row .pass-column-max {
    width: 100%;
}

.title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    overflow: hidden;
}

.status_container {
    font-weight: 600;
    border-radius: 4px;
    text-align: center;
    font-size: 11px;
    line-height: normal;
    padding: 0.2rem 0.4rem;
    min-width: 4rem;
    display: block;
}

.text_gray {
    color: #ADB5BD;
}

.text_gray-500 {
    color: #ADB5BD;
    font-weight: 500;
}

.loading {
    text-align: center;
    padding-top: 10rem;
}

.params {
    padding: 0 0.5rem;
    height: 2.6rem;
    border-left: 1px solid rgba(222, 226, 230, 1);
    border-bottom: 1px solid rgba(222, 226, 230, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.param {
    padding: 0.5rem;
    border-bottom: 1px solid rgba(222, 226, 230, 1);
    height: 2.6rem;
    display: flex;
    align-items: center;
}

.param__label {
    font-weight: 600;
}

.param__objectfunc {
    height: 1rem;
    overflow: hidden;
}

.params_list {
    overflow: auto;
    height: 50%;
}

.products {
    padding: 0 0.7rem;
    height: 2.6rem;
    /* border-left: 1px solid rgba(222, 226, 230, 1); */
    border-bottom: 1px solid rgba(222, 226, 230, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.products:last-child {
    border-bottom: 0;
}

.product__param_title {
    overflow: hidden;
    height: 1rem;
    width: 33rem;
}

.product__param {
    width: 10rem;
    display: flex;
}
</style>
