import Dashboard from "@/pages/AppDashboard"
import BuildingObject from "@/components/restfields/BuildingObject"
import PageNotFound from "@/pages/PageNotFound"
import FieldsSettings from '@/pages/FieldsSettings'
import { createRouter, createWebHistory } from "vue-router"

const routes = [
    {
        path: '/pass',
        component: Dashboard,
    },
    {
        path: '/restfields',
        component: FieldsSettings,
    },
    {
        name: 'building',
        path: '/restfields/buildingobject',
        component: BuildingObject,
    },
    {
        name: '404',
        path: '/:pathMatch(.*)*', 
        component: PageNotFound,
    }
]

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL)
})

export default router;