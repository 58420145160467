<template>
    <div class="mylist scrollbar">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "my-list"
}
</script>

<style scoped>
.mylist {
    overflow: auto;
    height: 109px;
}
</style>

