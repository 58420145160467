<template>
    <pass-row v-if="deal.assigner[0].id > 0"
        :title="deal.assigner[0].name"
        :link="`/company/personal/user/${deal.assigner[0].id}/`"
    >
        <template v-slot:leftSide>
            <open-path
                class="person_name"
                :link="`/crm/deal/details/${deal.id}/`"
                target="_blank"
            >#{{ deal.id }}</open-path>
            <deal-status
                class="status"
                :type="statusMap[deal.id]"
                :title="title"
            ></deal-status>
        </template>
        <template v-slot:rightSide>
            <span v-if="deal.oppotunity == 'Скрыто'" class="deal__amount">{{ deal.oppotunity }}</span>
            <span v-else class="deal__amount">{{ numberFormat(deal.oppotunity) }} {{ deal.currency }}</span>
        </template>
    </pass-row>
    <pass-row v-else
        :title="deal.assigner[0].name"
    >
        <template v-slot:leftSide>
            <open-path
                class="person_name"
                :link="`/crm/deal/details/${deal.id}/`"
                target="_blank"
            >#{{ deal.id }}</open-path>
            <deal-status
                class="status"
                :type="statusMap[deal.id]"
                :title="title"
            ></deal-status>
        </template>
        <template v-slot:rightSide>
            <span v-if="deal.oppotunity == 'Скрыто'" class="deal__amount">{{ deal.oppotunity }}</span>
            <span v-else class="deal__amount">{{ numberFormat(deal.oppotunity) }} {{ deal.currency }}</span>
        </template>
    </pass-row>
</template>

<script>
import PassRow from '@/components/UI/PassRow';
import DealStatus from '@/components/UI/DealStatus'
import { mapState } from 'vuex';
import OpenPath from '@/components/OpenPath';
export default {
    components: {
    DealStatus,
    PassRow,
    OpenPath,
},
    props: {
        deal: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            statusMap: state => state.pass.statusMap
        }),
        title() {
            const titleMap = {
                mainRegistered: 'Зарегистрирована. Главная',
                registered: 'Зарегистрирована',
                reReg: 'Требует перерегистрации',
                fail: 'Отклонена',
                transferred: 'Передана',
                refusal: 'Отказ (занят)',
                hidden: 'Скрыто'
            }
            return titleMap[this.statusMap[this.deal.id]]
        }
    }
}
</script>

<style scoped>
.deal__amount {
    padding: 2px 5px;


    background: #E9E7EE;
    border-radius: 4px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;

    color: #2067B0;
}

.status {
    margin-left: 0.5rem;
}
</style>