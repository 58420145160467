<template>
    <div class="products">
        <div 
            v-for="(param, key) in params" :key="key" :class="[key == 'title' ? {'product__param_title': true} : {'product__param': true}, 'text_gray-500']"
        >{{ param }}</div>
    </div>
</template>

<script>
export default {
    props: {
        params: {
            type: Object,
            // requared: true
            required: true
        }

    }
}
</script>

<!-- <style scoped>
.products {
    padding: 0 16px;
    height: 3.2rem;
    border-left: 1px solid rgba(222, 226, 230, 1);
    border-bottom: 1px solid rgba(222, 226, 230, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.product__param_title {
    width: 33rem;
}
.product__param {
    width: 10rem;
    display: flex;
}
</style> -->