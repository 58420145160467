<template>
    <span :class="[classObject, statusStyle]">{{ title }}</span>
</template>

<script>
export default {
    data() {
        return {
            classObject: {
                'status_container': true
            }
        }
    },
    props: {
        type: {
            type: String,
            require: true
        },
        title: {
            type: String,
            require: true
        }
    },
    computed: {
        statusStyle() {
            const styleMap = {
                mainRegistered: 'mainRegistered',
                registered: 'registered',
                reReg: 're-registration',
                fail: 'dismissed',
                transferred: 'transferred',
                refusal: 'refusal',
                hidden: 'hidden'
            }
            return styleMap[this.type]
        }
    }
}
</script>

<style scoped>
.mainRegistered {
    background-color: rgba(142, 217, 183, 1);
    color: #0E4F31;
}

.registered {
    background-color: rgba(209, 231, 221, 1);
    color: rgba(17, 89, 56, 1);
}

.re-registration {
    background-color: rgba(255, 243, 205, 1);
    color:rgba(102, 77, 3, 1);
}
.dismissed {
    background-color: rgba(243, 206, 194, 1);
    color: rgba(216, 79, 60, 1);
}
.transferred {
    background-color: rgba(186, 221, 255, 1);
    color: rgba(0, 129, 255, 1);
}
.refusal {
    background-color: rgba(243, 206, 194, 1);
    color: rgba(216, 79, 60, 1);
}
</style>