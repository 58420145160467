import axios from 'axios';

export const passModule = {
    state: () => ({
        isLoading: true,
        projectIsset: false,
        error: '',
        auth: {},
        dealId: {},
        userId: {},
        project: {},
        building: {},
        object: {},
        members: {},
        statusMap: {},
        products: [],
        bx24: {}
    }),
    mutations: {
        setError(state, error) {
            state.error = error
        },
        setbx24(state, bx) {
            state.bx24 = bx
        },
        setProducts(state, products) {
            state.products = products
        },
        setStatusMap(state, statusMap) {
            state.statusMap = statusMap
        },
        setObject(state, obj) {
            state.object = obj
        },
        setProject(state, project) {
            state.projectIsset = true
            state.project = project
        },
        setBuilding(state, building) {
            state.building = building
        },
        setMembers(state, members) {
            state.members = members
        },
        setLoading(state, bool) {
            state.isLoading = bool
        },
        setAuth(state, obj) {
            state.auth = obj
        },
        setUser(state, id) {
            state.userId = id
        },
        setPlacement(state, obj) {
            state.dealId = obj
        },
    },
    actions: {
        async fetchProject({ state, commit }) {
            try {
                let response = await axios({
                    method: 'POST',
                    url: process.env.VUE_APP_API + '/project',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        "dealId": state.dealId,
                        "userId": state.userId
                    }
                })

                let deals = response.data.deals
                let dealsarr = []
                const statusMap = {}

                for (let index in deals) {
                    switch (deals[index].registrationStatus) {
                        case 'DT181_10:FAIL': //отменена
                            statusMap[deals[index].id] = 'fail'
                            break
                        case 'DT181_10:SUCCESS': //зарегистрирована
                            deals[index].id == response.data.mainDeal ? statusMap[deals[index].id] = 'mainRegistered' : statusMap[deals[index].id] = 'registered'
                            break
                        case 'DT181_10:UC_62TWX8': //нужна перерегистрация
                            statusMap[deals[index].id] = 'reReg'
                            break
                        case 'DT181_10:UC_WU5A8V': //передана
                            statusMap[deals[index].id] = 'transferred'
                            break
                        case 'DT181_10:UC_H0319W': //отказ (занят)
                            statusMap[deals[index].id] = 'refusal'
                            break
                        case 'hidden':
                            statusMap[deals[index].id] = 'hidden'
                            break
                        default:
                            delete deals[index]
                    }
                    dealsarr.push(deals[index])
                }

                response.data.deals = dealsarr.sort((a, b) => {
                    if (statusMap[a.id] == statusMap[b.id]) { //одинаковые
                        return 0;
                    } else if (statusMap[a.id] == 'mainRegistered' || statusMap[a.id] == 'registered' && statusMap[b.id] !== 'mainRegistered') { //главная одна, если зарегистрирована - двигаем выше
                        return -1;
                    } else if (statusMap[a.id] == 'reReg' && statusMap[b.id] !== 'registered' && statusMap[b.id] !== 'mainRegistered') {
                        return -1;
                    } else {
                        return 1;
                    }
                })

                // if (response)
                //     throw new Error("PermissionError")

                commit('setStatusMap', statusMap)
                commit('setProject', response.data)
            } catch (e) {
                // if (e instanceof Error) {
                //     commit('setError', "У вас нет прав для просмотра этого проекта.")
                // }
                // else
                if (e.response?.data?.error == 500 && e.response?.data?.error_description == "Не привязан проект") {
                    commit('setError', e.response.data.error_description)
                    console.log('Проект не привязан!')
                } else {
                    console.log(e)
                }

                if (e.response?.data?.error_description == "Доступ запрещен") {
                    commit('setError', 'У вас нет прав для просмотра этого проекта.')
                }
            } finally {
                commit('setLoading', false)
            }
        },
        async fetchBuilding({ state, commit }) {
            try {
                let response = await axios({
                    method: 'POST',
                    url: process.env.VUE_APP_API + '/building',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        "dealId": state.dealId,
                        "userId": state.userId
                    }
                })
                commit('setBuilding', response.data)
            } catch (e) {
                if (e.response?.data?.error_description == "Свойство отсутствует") {
                    console.log('Здание - обязательное поле отсутствует!')
                } else {
                    console.log(e)
                }
            }
        },
        async fetchObject({ state, commit }) {
            try {
                let response = await axios({
                    method: 'POST',
                    url: process.env.VUE_APP_API + '/object',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        "dealId": state.dealId,
                        "userId": state.userId
                    }
                })
                commit('setObject', response.data)
            } catch (e) {
                if (e.response?.data?.error_description == "Свойство отсутствует") {
                    console.log('Объект - обязательное поле отсутствует!')
                } else {
                    console.log(e)
                }
            }
        },
        async fetchMembers({ state, commit }) {
            try {
                let response = await axios({
                    method: 'POST',
                    url: process.env.VUE_APP_API + '/members',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        "dealId": state.dealId,
                        "userId": state.userId
                    }
                })
                commit('setMembers', response.data)
            } catch (e) {
                if (e.response?.data?.error_description == "Свойство отсутствует") {
                    console.log('Участники - обязательное поле отсутствует!')
                } else {
                    console.log(e)
                }
            }
        },
        async fetchProducts({ state, commit }) {
            try {
                let response = await axios({
                    method: 'POST',
                    url: process.env.VUE_APP_API + '/products',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        "dealId": state.dealId,
                        "userId": state.userId
                    }
                })

                let products = response.data.products
                let productsarr = []
                const statusMap = {}

                for (let index in products) {
                    switch (products[index].registrationStatus) {
                        case 'DT181_10:FAIL': //отменена
                            statusMap[products[index].id] = 'fail'
                            break
                        case 'DT181_10:SUCCESS': //зарегистрирована
                        products[index].id == response.data.mainDeal ? statusMap[products[index].id] = 'mainRegistered' : statusMap[products[index].id] = 'registered'
                            break
                        case 'DT181_10:UC_62TWX8': //нужна перерегистрация
                            statusMap[products[index].id] = 'reReg'
                            break
                        case 'DT181_10:UC_WU5A8V': //передана
                            statusMap[products[index].id] = 'transferred'
                            break
                        case 'DT181_10:UC_H0319W': //отказ (занят)
                            statusMap[products[index].id] = 'refusal'
                            break
                        case 'hidden':
                            statusMap[products[index].id] = 'hidden'
                            break
                        default:
                            delete products[index]
                    }
                    productsarr.push(products[index])
                }

                response.data.products = productsarr.sort((a, b) => {
                    if (statusMap[a.id] == statusMap[b.id]) { //одинаковые
                        return 0;
                    } else if (statusMap[a.id] == 'mainRegistered' || statusMap[a.id] == 'registered' && statusMap[b.id] !== 'mainRegistered') { //главная одна, если зарегистрирована - двигаем выше
                        return -1;
                    } else if (statusMap[a.id] == 'reReg' && statusMap[b.id] !== 'registered' && statusMap[b.id] !== 'mainRegistered') {
                        return -1;
                    } else {
                        return 1;
                    }
                })

                commit('setProducts', response.data.products)
            } catch (e) {
                console.log(e)
            }
        },
    },
    namespaced: true
}
