<template>
    <div class="param">
        <div class="param__label w-50">{{ param.title }}:</div>
        <!-- <div class="param__value w-25 text-end">{{ numberFormat(param.object) }}</div> -->
        <div class="param__value w-25 text-end">{{ param.object }}</div>
        <div class="param__value w-25 text-end">{{ param.building }}</div>
    </div>
</template>

<script>
export default {
    props: {
        param: {
            type: Object,
            required: true
        }
    },
}
</script>

<style scoped>
.param {
    border-left: 1px solid rgba(222, 226, 230, 1);
}
</style>