<template>
    <div class="mycard">
        <div class="mycard__header">
            <div class="mycard__title">
                <slot name="title"></slot>
            </div>
            <div class="">
                <span v-if="param" class="header_param">{{ param }}</span>
            </div>
        </div>
        <slot class="overflow-scroll" name="body"></slot>
    </div>
</template>

<script>
export default {
    name: "pass-card",
    props: {
        param: {
            type: String,
            // requared: false
            required: false
        }
    }
}
</script>

<style scoped>
.mycard {
    /* height: 13.1rem; */
    width: 100%;
    border-radius: 12px; 
    border: 1px solid rgba(222, 226, 230, 1);
    overflow: hidden;
    background: #FFF;
    min-height: 157px;
    max-height: 157px;
}

.mycard__header {
    height: 2.8rem;
    padding: 0.6rem;
    border-bottom: 1px solid rgba(222, 226, 230, 1);
    background-color: #f8f9fa;
    display: flex;
    justify-content: space-between;
}
.mycard__title {
    display: flex;
    justify-content: space-between;
    gap: 0 4px;
}
.header_param {
    margin-left: auto;
    padding: 0.35rem 1rem;
    
    background: #E9E7EE;
    border-radius: 4px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;

    color: #2067B0;
}
</style>