<template>
    <pass-card>
        <template v-slot:title>
            <project-icon></project-icon>
            <div class="title">Проект:</div>
            <open-path
                :title="project.title"
                class="title width"
                :link="`/crm/type/175/details/${project.id}/`"
            >{{ project.title }}</open-path>
        </template>
        <template v-slot:body>
            <my-list>
                <template v-for="deal in project.deals" :key="deal?.id">
                    <project-item v-if="deal" :deal="deal" />
                </template>
            </my-list>
        </template>
    </pass-card>
</template>

<script>
import ProjectIcon from '@/components/UI/icons/ProjectIcon'
import ProjectItem from '@/components/ProjectItem';
import { mapState } from 'vuex';
import OpenPath from './OpenPath.vue';

export default {
    components: {
        ProjectIcon,
        ProjectItem,
        OpenPath
    },
    computed: {
        ...mapState({
            project: state => state.pass.project,
        })
    },
}
</script>

<style scoped>
.width {
    width: 31rem;
}
</style>
