<template>
  <h1 class="error">
    404 not found
  </h1>
</template>

<style scoped>
.error {
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
}
</style>
