<template>
  <select
    class="field__select"
    :value="modelValue"
    @change="changeOption"
  >
    <option class="filed__option" :value="''">Не выбрано</option>
    <option
      v-for="option in options"
      class="filed__option"
      :key="option.id"
      :value="option.id"
    >{{ option.title }}</option>
  </select>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    changeOption(event) {
      this.$emit("update:modelValue", event.target.value)
    }
  }
}
</script>

<style scoped>
.field__select {
  display: block;
  box-sizing: border-box;
  padding: 10px 9px;
  height: 3.5rem;
  max-width: 100%;
  width: 100%;
  outline: 0;
  border: 1px solid #c4c7cc;
  border-radius: .3rem;
  background-color: #fff;
  color: #424956;
  font-size: 14px;
  transition: 220ms all ease;
}
.filed__option {
  font-size: 14px;
}
</style>