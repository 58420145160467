<template>
    <pass-card :param="'Директор'">
        <template v-slot:title>
            <building-icon />
            <div class="title">Здание:</div>
            <open-path
                :title="building.title"
                class="title width"
                :link="'/crm/type/171/details/' + building.id + '/'"
            >{{ building.title }}</open-path>
        </template>
        <template v-slot:body>
            <div class="d-flex flex-row overflow-hidden">
                <div class="flex w-50">
                    <div class="param">
                        <div class="param__objectfunc">
                            <strong class="param__label">Функция объекта: </strong>
                            {{ object.function ?? '-' }}
                        </div>
                    </div>
                    <div class="param">
                        <strong class="param__label">Стадия объекта: </strong>
                        <object-status
                            :type="'done'"
                            style="margin-left: 0.5rem;"
                        >{{ object.stage ?? '-' }}</object-status>
                    </div>
                    <div class="param">
                        <div><strong class="param__label">Этап: </strong>
                            {{ object.step ?? '-' }}
                        </div>
                    </div>
                    <div class="param">
                        <div><strong class="param__label">Кадастровый номер: </strong>
                            {{ object.cadastralNumber ?? '-' }}
                        </div>
                    </div>
                </div>
                <div class="flex w-50">
                    <div class="params">
                        <gray-text class="w-50">Параметры</gray-text>
                        <gray-text class="text-end w-25">Объект</gray-text>
                        <gray-text class="text-end w-25">Здание</gray-text>
                    </div>
                    <div
                        v-if="Object.keys(building).length !== 0 && Object.keys(object).length !== 0"
                        class="params_list scrollbar"
                    >
                        <building-param
                            v-for="(param, index) in buildingParams"
                            :key="index"
                            :param="param"
                        ></building-param>
                    </div>
                </div>
            </div>
        </template>
    </pass-card>
</template>

<script>
import BuildingIcon from '@/components/UI/icons/BuildingIcon';
import ObjectStatus from '@/components/UI/ObjectStatus';
import BuildingParam from '@/components/BuildingParam';
import OpenPath from '@/components/OpenPath';
import { mapState } from 'vuex';

const numberFormat = (() => new Intl.NumberFormat("ru-RU").format)()

export default {
    components: {
        BuildingIcon,
        ObjectStatus,
        BuildingParam,
        OpenPath,
    },
    computed: {
        ...mapState({
            building: state => state.pass.building,
            object: state => state.pass.object,
        }),
        buildingParams() {
            let objectFloor = [] || ''

            if (this.object.floor == null){
                objectFloor = '-'
            } else {
                for (let index in this.object.floor) {
                    if (this.object.floor[index] == 'Все этажи'){
                        objectFloor = this.object.floor[index] ?? '-'
                    } else {
                        objectFloor.push(this.object.floor[index])
                        // objectFloor = objectFloor.toString()
                    }
                }

                objectFloor = objectFloor.toString()
            }

            let params = [
                {
                    title: 'Этажность',
                    object: objectFloor,
                    building: this.building.floor ?? '-'
                },
                {
                    title: 'Общая площадь',
                    object: this.object.totalArea ? numberFormat(this.object.totalArea) + ' (м2)' : '-',
                    building: this.building.totalArea ? numberFormat(this.building.totalArea) + ' (м2)' : '-'
                },
                {
                    title: 'Основные',
                    object: this.object.mainArea ?? '-',
                    building: this.building.mainArea ?? '-'
                },
                {
                    title: 'Вспомогательные',
                    object: this.object.additionalArea ? numberFormat(this.object.additionalArea) + ' (м2)' : '-',
                    building: this.building.additionalArea ? numberFormat(this.building.additionalArea) + ' (м2)' : '-'
                },
                {
                    title: 'Наружные/Outdoor территории',
                    object: this.object.outdoorArea ? numberFormat(this.object.outdoorArea) + ' (м2)' : '-',
                    building: this.building.outdoorArea ? numberFormat(this.building.outdoorArea) + ' (м2)' : '-'
                },
            ]
            return params
        }
    }
}
</script>

<style scoped>
.width {
    width: 31rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>